import React, { useState, useRef } from "react"
import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from 'gatsby-background-image'
import Slider from "react-slick";
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row, Col, Button } from "reactstrap"

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


const TvnPhotosPage = () => {

  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();
  const mainSlider = useRef();
  const thumbsSlider = useRef();

  const data = useStaticQuery(graphql`
    query {
      photos: allFile(
        filter: { absolutePath: { regex: "/images/tvn/photos/" } }
        sort: { fields: name }
      ) {
        edges {
          node {
            childImageSharp {
              fluid(quality: 90, maxHeight: 650, fit: CONTAIN) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          thumb: node {
            childImageSharp {
              fixed(quality: 90, height: 250) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          }
        }
      }
    }
  `)

  const mainSettings = {
    dots: false,
    centerMode: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };
  const thumbsSettings = {
    className: "center variable-width",
    dots: false,
    centerMode: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    variableWidth: true,
    focusOnSelect: true
  };

  const mainPhotos = data.photos.edges.map((item, key) => {
    return (
      <div key={key}>
        <div style={{ maxHeight: 100, maxWidth: 1000, margin: "0 auto" }}>
          <Img fluid={item.node.childImageSharp.fluid} imgStyle={{ objectFit: "contain" }} className="slider-image" />
        </div>
      </div>
    )
  })

  const thumbPhotos = data.photos.edges.map((item, key) => {
    return (
      <div key={key}>
        <div style={{ margin: "0 auto" }}>
          <img src={item.thumb.childImageSharp.fixed.src} />
        </div>
      </div>
    )
  })

  return (
    <Layout className="subpage">
      <SEO
        title="TVN: Patent na dom | Zdjęcia z planu"
        description="Nasza ekipa wspólnie z Marco Belotto oraz Anną Nowak-Ibisz realizuje metamorfozy w programie Patent na dom."
      />
      <section className="tvn-photos pb-0 pt-0 black-bg">
        <Container>
          <Row>
            <Col>
              <Slider {...mainSettings} className="slider-main" asNavFor={nav2} ref={slider => setNav1(slider)}>
                {mainPhotos}
              </Slider>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="tvn-photos pb-0 white-bg d-none d-lg-block">
        <Container fluid>
          <Row>
            <Col>
              <Slider {...thumbsSettings} className="slider-thumbs" asNavFor={nav1} ref={slider => setNav2(slider)}>
                {thumbPhotos}
              </Slider>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="tvn-photos-claim pb-5 white-bg">
        <Container>
          <Row>
            <Col className="d-none d-lg-block">
            </Col>
            <Col md="6">
              <h2>"Patent na dom"</h2>
            </Col>
            <Col>
              <p>Nasza ekipa razem z Joanną Jabłońską - szefową Nobo Design wzięła udział w nagraniu premierowych odcinków programu TVN "Patent na dom".</p>
              <p>Nowy ekspert - nasz zaprzyjaźniony architekt Marco Belotto we współpracy z Anną Nowak-Ibisz dokonają metamorfozy wnętrza za pomocą kilku patentów, które zrealizowaliśmy.</p>
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  )
}

export default TvnPhotosPage